import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Social = () => import(/* webpackChunkName: "home" */'../views/Social');
const Media = () => import(/* webpackChunkName: "home" */'../views/Media');
const Covid19 = () => import(/* webpackChunkName: "home" */'../views/Covid-19');
const Contact = () => import(/* webpackChunkName: "home" */'../views/Contact');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/social/',
            name: 'Social',
            component: Social
        },
        {
            path: '/media/',
            name: 'Media',
            component: Media
        },
        {
            path: '/covid-19/',
            name: 'Covid19',
            component: Covid19
        },
        {
            path: '/contact/',
            name: 'Contact',
            component: Contact
        }
    ]
};
